import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/services/service.service';

@Component({
  selector: 'app-online-payment',
  templateUrl: './online-payment.component.html',
  styleUrls: ['./online-payment.component.scss']
})
export class OnlinePaymentComponent implements OnInit {

  imagedetails:any;
  url:any;
  filter:any={};
  p: number = 1;
  total:any;
  isLoading:boolean = false;
  payList: any;
  constructor(private service:ServiceService) { }

  ngOnInit(): void {
    this.filter={
      pageNo:1,
      size:10
    };
    // this.url = this.service.videoUrl;
    this.getPayment(this.filter);
  }

  getPayment(filter:any) {
    this.isLoading =true;
    this.service.getAllOnlinePayment(filter).subscribe((res:any) => {
      console.log(res);
       this.payList = res.data;
       this.total = res.total;
       this.isLoading =false;
    });
  }

  onTableDataChange(event: any) {
    this.filter.pageNo = event;
    this.getPayment(this.filter);
    this.p = event;
    window.scrollTo({
     top: 0,
     behavior: 'smooth'
   });
 }
}
